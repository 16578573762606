(function( global, factory ) {
    if ( typeof define === "function" && define.amd ) {
        // AMD. Register as an anonymous module.
        define('cookieblock', [ "jquery", "require" ], factory );
    } else {
        // Browser globals
        global.CookieBlock = factory( jQuery, function (name) {
            var result = global;
            name.split('.').map(function(namePart) {
                result = result[namePart];
            });
            return result;
        } );
    }
} (this, function($, require) {
    "use strict";

    var domains = [
        '',
        // 'simplybook.me',
        // 'simplybook.asia',
        // 'simplybook.it'
    ];

    var handlersDelay = 1000; //ms
    var acceptedHandlers = [];
    window.addOnCookiesAccepted = function (handler) {
        acceptedHandlers.push(handler);
    };

    var setCookiesAccepted = function (value, handler) {
        $.cookie('cookies_accepted', value, {
            expires: 365,
            path: '/',
            secure: true
        });

        domains.map(function (domain) {
            if (domain) {
                return 'https://' + domain + '/acceptcookie.php?v=' + value + '&d=' + domain;
            } else {
                return '/acceptcookie.php?v=' + value;
            }
        }).forEach(function (src) {
            // $('<script />').attr('src', src).appendTo('body');
            $.getJSON(src, function (consent) {
                window.dataLayer = window.dataLayer || [];
                function gtag(){dataLayer.push(arguments);}

                console.log(consent);
                if(consent){
                    gtag('consent', 'update', consent);
                }

                if(handler){
                    handler(consent, src);
                }
            });
        });
    };

    var CookieBlock = {
        isShown: false,

        acceptAll: function () {
            setCookiesAccepted('yes');

            setTimeout(function () {
                acceptedHandlers.forEach(function (handler) {
                    handler();
                });
            }, handlersDelay);
        },

        acceptNeccessary: function () {
            setCookiesAccepted('necessary');
        },

        rejectAll: function () {
            setCookiesAccepted('no');
        },


        show: function () {
            var _this = this;

            $(document).on('click', '#accept_important_coo', function (e) {
                e.preventDefault();
                _this.acceptAll();
                $('.sb-important').fadeOut();
                $('.sb-important-cover').fadeOut();
                return false;
            });

            $(document).on('click', '#selected_important_coo', function (e) {
                e.stopPropagation();
                e.preventDefault();
                var isMarketing = $('#sb_marketing_cookie_accept').prop('checked');
                if(isMarketing){
                    _this.acceptAll();
                } else {
                    _this.acceptNeccessary();
                }
                $('.sb-important').fadeOut();
                $('.sb-important-cover').fadeOut();
                return false;
            });

            $(document).on('click', '#necessary_important_coo', function (e) {
                e.stopPropagation();
                e.preventDefault();
                _this.acceptNeccessary();
                $('.sb-important').fadeOut();
                $('.sb-important-cover').fadeOut();
                return false;
            });

            $(document).on('click', '#disagree_cookies', function (e) {
                e.stopPropagation();
                e.preventDefault();
                _this.rejectAll();
                $('.sb-important').fadeOut();
                $('.sb-important-cover').fadeOut();
                return false;
            });
        },

        addAcceptedHandler: function (handler) {
            acceptedHandlers.push(handler);
        },

    };


    CookieBlock.show();

    return CookieBlock;

}));
